import React from "react";

const RadioError = () => (
  <svg width="176" height="136" viewBox="0 0 176 136">
    <defs>
      <path id="a" d="M.107.305h23.786v12.221H.107z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        className="cls-1"
        fill="#F4F4F8"
        d="M69.785 95.858l18.794 22.574-75.929 4.135c-4.475.243-8.335-3.197-8.58-7.646L.011 41.258c-.245-4.45 3.215-8.288 7.69-8.532l75.184-4.094-13.344 21.702 11.741 15.392-11.498 30.132z"
      />
      <path
        className="cls-1"
        fill="#F4F4F8"
        d="M175.343 41.861l-5.857 74.099c-.353 4.475-4.274 7.844-8.71 7.488l-65.403-5.262-15.741-25.821 15.63-28.241-9.71-16.538 16.009-19.85 66.357 5.34c4.436.356 7.779 4.31 7.425 8.785"
      />
      <path
        className="cls-2"
        fill="#8D929E"
        d="M35.776 126.475c.265 4.776-3.481 8.898-8.326 9.16l-6.525.352c-4.844.261-9.025-3.432-9.29-8.208-.068-1.229.887-2.28 2.133-2.347l19.628-1.06c1.246-.067 2.312.874 2.38 2.103"
      />
      <g transform="translate(137.79 123.474)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          className="cls-2"
          fill="#8D929E"
          d="M23.886 4.34c-.38 4.875-4.59 8.547-9.357 8.158l-6.42-.524C3.343 11.585-.246 7.278.135 2.402.232 1.148 1.305.212 2.532.312l19.311 1.575c1.226.1 2.14 1.198 2.043 2.452"
          mask="url(#b)"
        />
      </g>
      <path
        className="cls-2"
        fill="#8D929E"
        d="M68.855 73.74c.784 14.307-10.18 26.542-24.489 27.326-14.308.783-26.543-10.18-27.326-24.49-.784-14.308 10.18-26.542 24.488-27.326 14.309-.784 26.544 10.18 27.327 24.49"
      />
      <path
        className="cls-1"
        fill="#F4F4F8"
        d="M13.8 37.84a3.011 3.011 0 0 1 1.145-4.08L74.292.38a2.99 2.99 0 0 1 4.065 1.15 3.011 3.011 0 0 1-1.145 4.08L17.866 38.987a2.99 2.99 0 0 1-4.066-1.149"
      />
      <path
        className="cls-2"
        fill="#8D929E"
        d="M141.378 60.815c.133-1.583 1.603-2.774 3.267-2.648l6.456.49c1.664.126 2.917 1.524 2.784 3.107-.133 1.583-1.602 2.774-3.267 2.648l-6.456-.49c-1.664-.126-2.917-1.524-2.784-3.107M93.957 72.45c.13-1.585 1.564-2.778 3.188-2.651l52.234 4.062c1.623.127 2.846 1.526 2.717 3.11-.13 1.585-1.564 2.778-3.188 2.651L96.674 75.56c-1.624-.126-2.847-1.525-2.717-3.11M95.746 57.382c.131-1.67 1.58-2.926 3.22-2.793l35.176 2.856c1.64.133 2.874 1.608 2.743 3.278-.13 1.67-1.579 2.927-3.218 2.794L98.49 60.66c-1.64-.133-2.875-1.608-2.744-3.278M92.167 87.66c.13-1.585 1.565-2.777 3.189-2.65l52.233 4.062c1.624.126 2.847 1.526 2.717 3.11-.13 1.585-1.564 2.777-3.188 2.65L94.885 90.77c-1.624-.127-2.847-1.526-2.718-3.11"
      />
    </g>
  </svg>
);

export default RadioError;
