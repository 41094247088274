import React, { useState } from "react";
import { useInfiniteQuery } from "react-query";
import { Container, Grid, LinearProgress, Typography } from "@material-ui/core";
import { getAllSeries, isEmpty } from "../../utils";
import { Series } from "../../models";
import InfinityScroll from "../../InfinityScroll";
import SeriesCard from "../../SeriesCard";
import UnknownError from "../../UnknownError";
import AlphabetShortcut from "./AlphabetShortcut";

function SeriesOverview() {
  const [letter, setLetter] = useState<string>("all");
  const {
    data,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
    error,
  } = useInfiniteQuery<{
    moreItemsExist: boolean;
    nextSkip: number;
    series: Series[];
  }>(["getAllSeries", { letter }], getAllSeries, {
    getFetchMore: (lastGroup) => lastGroup.moreItemsExist && lastGroup.nextSkip,
    refetchOnWindowFocus: false,
  });

  const handleLetterChange = (value: string) => {
    setLetter(value);
  };
  const [firstResponse] = data || [];

  return (
    <>
      <div style={{ minHeight: 20 }}>
        {isFetching && (
          <LinearProgress data-testid="liniar-progress" color="secondary" />
        )}
      </div>

      <Container>
        <Typography variant="h1" paragraph>
          Alle programma's
        </Typography>
        <AlphabetShortcut
          onLetterChange={handleLetterChange}
          activeLetter={letter}
        />
        {(error || (!isFetching && isEmpty(firstResponse.series))) && (
          <UnknownError />
        )}
        <Grid container spacing={3}>
          {data?.map(({ series, nextSkip }) => (
            <React.Fragment key={nextSkip}>
              {series?.map((singleSeries) => (
                <Grid key={singleSeries.id} item xs={12} sm={4} md={3} lg={2}>
                  <SeriesCard series={singleSeries} />
                </Grid>
              ))}
            </React.Fragment>
          ))}
        </Grid>
        <InfinityScroll
          fetchMore={fetchMore}
          canFetchMore={!!canFetchMore}
          isFetching={isFetching}
          isFetchingMore={!!isFetchingMore}
          showLoading={isFetching && !data}
        />
      </Container>
    </>
  );
}

export default SeriesOverview;
