import { GraphqlProgram } from "../models";

export const sortVideos = (sort = "ADDED") => (
  a: GraphqlProgram,
  b: GraphqlProgram
) => {
  if (sort === "ADDED") {
    return (b.added || 0) - (a.added || 0);
  }
  if (sort === "ADDED_ASC") {
    return (a.added || 0) - (b.added || 0);
  }
  if (sort === "LASTPUBDATE") {
    return (a.epgDate || 0) - (b.epgDate || 0);
  }
  if (sort === "LASTPUBDATE_DESC") {
    return (b.epgDate || 0) - (a.epgDate || 0);
  }
  if (sort === "TITLE") {
    return (b.title || b.slug || "") > (a.title || a.slug || "") ? -1 : 1;
  }
  if (sort === "TITLE_DESC") {
    return (a.title || a.slug || "") > (b.title || b.slug || "") ? -1 : 1;
  }
  return 0;
};
