import React from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  OUTPUT_OPTIONS,
  PROGRAM_TYPE_OPTIONS,
  SORT_OPTIONS,
} from "../constants";
import { RouteParams } from "../models";
import { getProgramTypes } from "../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-end",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    formInput: {
      padding: theme.spacing(0, 1),
    },
  })
);
const output = "html";

function Settings({ onRefetch }: { onRefetch?: () => void }) {
  const history = useHistory();
  const classes = useStyles();
  const params = useParams<RouteParams>();
  const { brand, sort, slug } = params;

  const { key: programType } = getProgramTypes(params);

  const handleOutputChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const targetOutput = event.target.value as string;

    const url = `${window.location.pathname.replace(
      "/html/",
      `/${targetOutput}/`
    )}`;
    window.open(url, "_blank");
  };

  const handleSortChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newSort = event.target.value as string;
    const page = window.location.href.includes("/search/") ? "search" : "video";
    let target = `/${page}/html/${newSort.toLowerCase()}/${brand}/${programType}/`;
    if (slug) {
      target += `${slug}/`;
    }
    history.push(target);
  };

  const handleProgramTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const newProgramType = event.target.value as string;
    const page = window.location.href.includes("/search/") ? "search" : "video";
    let target = `/${page}/html/${sort}/${brand}/${newProgramType}/`;
    if (slug) {
      target += `${slug}/`;
    }
    history.push(target);
  };

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <InputLabel id="output-label">Feed</InputLabel>
        <Select
          labelId="output-label"
          id="output"
          value={output}
          onChange={handleOutputChange}
          className={classes.formInput}
          inputProps={{
            "data-testid": "output-select",
          }}
        >
          {OUTPUT_OPTIONS.map(({ key, title }) => (
            <MenuItem key={key} value={key}>
              {title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="sort-label">Sorteren</InputLabel>
        <Select
          labelId="sort-label"
          id="sort"
          value={sort.toLocaleUpperCase()}
          onChange={handleSortChange}
          className={classes.formInput}
          inputProps={{
            "data-testid": "sort-select",
          }}
        >
          {SORT_OPTIONS.map(({ key, title }) => (
            <MenuItem key={key} value={key}>
              {title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="programType-label">Type</InputLabel>
        <Select
          labelId="programType-label"
          id="programType"
          value={programType}
          onChange={handleProgramTypeChange}
          className={classes.formInput}
          inputProps={{
            "data-testid": "program-type-select",
          }}
        >
          {PROGRAM_TYPE_OPTIONS.map(({ key, title }) => (
            <MenuItem key={key} value={key}>
              {title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        {onRefetch && <Button onClick={onRefetch}>Verversen</Button>}
      </FormControl>
    </div>
  );
}

export default Settings;
