import React, { Fragment, useEffect, useRef } from "react";
import {
  AppBar,
  Drawer,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  Theme,
  Toolbar,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { TaqLink } from "../TaqComponents";
import { links } from "../constants";
import SearchIcon from "./SearchIcon";
import MenuIcon from "./MenuIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      [theme.breakpoints.up("lg")]: {
        width: theme.breakpoints.values.lg,
        marginLeft: "auto",
        marginRight: "auto",
      },
      "&:hover $link": {
        color: theme.palette.secondary.light,
      },
      "& $link": {
        textAlign: "center",
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
    },
    logo: {
      marginRight: theme.spacing(5),
      verticalAlign: "middle",
    },
    searchIput: {
      borderRadius: 4,
      backgroundColor: "rgba(0, 0, 0, 0.15)",
      padding: "2px 5px",
      "& input": {
        width: 120,
        transition: "width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "&:focus": {
          width: 150,
        },
      },
    },
    searchContainer: {
      width: 225,
      textAlign: "right",
    },
    flex: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1),
      cursor: "pointer",
      width: 100,
      textAlign: "left",
      "&:hover span": {
        fontWeight: 500,
        color: theme.palette.secondary.main,
      },
    },
    drawer: {
      width: 240,
      padding: theme.spacing(1),
      '& $link': {
        width: '100%',
      },
    },
    drawerToggle: {
      marginLeft: theme.spacing(1),
    },
    brand: {
      marginRight: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    active: {
      "& span": {
        fontWeight: 500,
      },
    },
  })
);

function Header() {
  const classes = useStyles();
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement>(null);
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

  const location = useLocation();
  useEffect(() => {
    (window as any).Taq.push(["track", "view"]);
    setDrawerOpen(false);
  }, [location]);

  const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    /*istanbul ignore next*/
    if (!inputRef.current?.value) {
      return;
    }
    const path = `/search/html/added/all-channels/all/${inputRef.current.value}/`;

    history.push(path);
    inputRef.current.blur();
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Fragment>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <TaqLink
            to="/"
            color="secondary"
            component={NavLink}
            underline="none"
            eventCategory="header"
            eventName="header-Logo"
            eventLabel="menu"
          >
            <img
              src="/assets/talpa_logo.png"
              width="112"
              alt="Talpa Network logo"
              className={classes.logo}
            />
          </TaqLink>
          <TaqLink
            to="/"
            color="secondary"
            component={NavLink}
            underline="none"
            className={classes.brand}
            eventCategory="header"
            eventName="header-Talpa Video Feed"
            eventLabel="menu"
          >
            <Typography variant="h6">Talpa Video Feed</Typography>
          </TaqLink>
          <span className={classes.flex} />
          {links.map(({ inHeader, to, component, exact, href, target, title }) => (
            <Fragment key={title}>
              {inHeader && (
                <TaqLink
                  color="secondary"
                  className={classes.link}
                  activeClassName={classes.active}
                  underline="none"
                  eventCategory="header"
                  eventName={`header-${title}`}
                  eventLabel="menu"
                  {...{ to, exact: !!exact, href, target, component }}
                >
                  <Typography component="span" variant="body1">
                    {title}
                  </Typography>
                </TaqLink>
              )}
            </Fragment>
          ))}
          <div className={classes.searchContainer}>
            <form onSubmit={handleSearchSubmit}>
              <FormControl className={classes.searchIput}>
                <Input
                  inputProps={{
                    "aria-label": "Zoeken",
                    "data-testid": "search-input",
                    ref: inputRef,
                  }}
                  placeholder="Zoeken"
                  disableUnderline
                  type="text"
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </form>
          </div>
          <TaqLink
            component={IconButton}
            aria-label="open Menu"
            onClick={toggleDrawer}
            color="secondary"
            underline="none"
            className={classes.drawerToggle}
            eventCategory="header"
            eventName="header-Menu Open"
            eventLabel="menu"
          >
            <MenuIcon />
          </TaqLink>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer}
        className={classes.drawer}
        PaperProps={{
          className: classes.drawer,
        }}
      >
        <TaqLink
          component="button"
          onClick={toggleDrawer}
          color="secondary"
          className={classes.link}
          activeClassName={classes.active}
          underline="none"
          eventCategory="header"
          eventName="`header-Menu Sluiten"
          eventLabel="menu"
        >
          <Typography component="span" variant="body1">
            Sluiten
          </Typography>
        </TaqLink>
        {links.map(({ to, component, exact, href, target, title }) => (
          <TaqLink
            key={title}
            color="secondary"
            className={classes.link}
            activeClassName={classes.active}
            underline="none"
            eventCategory="header"
            eventName={`header-${title}`}
            eventLabel="menu"
            {...{ to, exact: !!exact, href, target, component }}
          >
            <Typography component="span" variant="body1">
              {title}
            </Typography>
          </TaqLink>
        ))}
      </Drawer>
    </Fragment>
  );
}

export default Header;
