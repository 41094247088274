declare var OneTrust: { ToggleInfoDisplay: () => void };

const getCookie = (cname: string): any => {
  const name: string = `${cname}=`;
  const decodedCookie: string = decodeURIComponent(document.cookie);
  const ca: string[] = decodedCookie.split(";");
  for (let c of ca) {
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

export const isVendorAllowed = (vendorId: number): boolean => {
  const consentedVendors = JSON.parse(getCookie("consentedvendors-v2"));
  console.log({ consentedVendors });
  if (consentedVendors) {
    const vendors = consentedVendors.consented_vendors_v2;
    return vendors.indexOf(vendorId) >= 0;
  }
  return false;
};

function removeHash() {
  window.history.replaceState(null, "", " ");
}

export const onHashChange = () => {
  if (window.location.hash.substr(1) === "consentmanager" && typeof OneTrust !== "undefined") {
    OneTrust.ToggleInfoDisplay();
    removeHash();
  }
};

// Wait for __tcfapi to be loaded
(function init() {
  const retryLimit = 20;
  let retry = 0;
  const interval = setInterval(() => {
    if (typeof OneTrust !== "undefined") {
      clearInterval(interval);
      onHashChange();
      return;
    }
    if (retry >= retryLimit) {
      clearInterval(interval);
      return;
    }
    retry++;
  }, 500);
})();
