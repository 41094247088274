import React, { Fragment } from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import { Container, Typography, LinearProgress, Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import {
  getBrand,
  getBrandGuids,
  getVideosByBrands,
  isEmpty,
} from "../../utils";
import VideoCard from "../../VideoCard";
import Settings from "../../Settings";
import { Brand, Video } from "../../models";
import InfinityScroll from "../../InfinityScroll";
import UnknownError from "../../UnknownError";
import SeriesSwitch from "../../SeriesSwitch";
interface BrandPageParams {
  sort: string;
  programType: string;
  brand: string;
}

function BrandPage() {
  const { sort, programType, brand: brandSlug } = useParams<BrandPageParams>();
  const {
    data: brand,
    error: brandError,
    isFetching: isFetchingBrand,
  } = useQuery<Brand | null>(
    [
      "brand",
      {
        slug: brandSlug,
      },
    ],
    getBrand
  );

  const { data: guids, isFetching: isFetchingBrandGuids } = useQuery<string[]>(
    [
      "brandGuids",
      {
        slug: brandSlug,
        sort,
        programType,
      },
    ],
    getBrandGuids
  );

  const {
    data,
    error,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
    refetch,
  } = useInfiniteQuery<{
    videos: Video[];
    nextSkip: number;
    moreItemsExist: boolean;
  }>(
    [
      "brandVideos",
      {
        sort,
        guid: guids,
        programType,
      },
    ],
    getVideosByBrands,
    {
      getFetchMore: (lastGroup) =>
        lastGroup.moreItemsExist && lastGroup.nextSkip,
      refetchOnWindowFocus: false,
    }
  );

  if (brandError || (!isFetchingBrand && !brand)) {
    return <UnknownError message={error ? "" : `No result for ${brandSlug}`} />;
  }

  const [firstResponse] = data || [];
  return (
    <>
      <div style={{ minHeight: 20 }}>
        {isFetching && (
          <LinearProgress data-testid="liniar-progress" color="secondary" />
        )}
      </div>
      <Container>
        <Settings onRefetch={refetch} />
        <Typography variant="h1" paragraph>
          {brand && brand.title}
        </Typography>
        <SeriesSwitch brandSlug={brandSlug} />
        <Fragment>
          <Grid container spacing={3}>
            {data?.map(({ videos, nextSkip }) => (
              <Fragment key={nextSkip}>
                {videos.map((video) => (
                  <Grid key={video.guid} item xs={12} sm={6} md={4} lg={3}>
                    <VideoCard video={video} />
                  </Grid>
                ))}
              </Fragment>
            ))}
          </Grid>
          <InfinityScroll
            fetchMore={fetchMore}
            canFetchMore={!!canFetchMore}
            isFetching={isFetchingBrandGuids || isFetching}
            isFetchingMore={!!isFetchingMore}
            showLoading={isFetching && isEmpty(firstResponse?.videos)}
          />
        </Fragment>
      </Container>
    </>
  );
}

export default BrandPage;
