import React from "react";
import { Link, LinkProps, LinkTypeMap } from "@material-ui/core";

interface TaqLinkProps {
  eventName?: string;
  eventCategory?: string;
  eventLabel?: string;
}
export const TaqLink = <
  D extends React.ElementType = LinkTypeMap["defaultComponent"],
  P = {}
>(
  props: LinkProps<D, P> & TaqLinkProps
) => {
  const { eventName, eventCategory, eventLabel, ...otherProps } = props;
  const handleClick = (
    event: React.MouseEvent<HTMLInputElement | HTMLAnchorElement>
  ) => {
    (window as any).Taq.push([
      "track",
      "link",
      {
        event_name: eventName || null,
        event_category: eventCategory || "event",
        event_action: "click",
        event_label: eventLabel || (event.target as HTMLElement).textContent,
      },
    ]);
  };
  return <Link onClickCapture={handleClick} {...otherProps} />;
};

export default TaqLink;
