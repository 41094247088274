import axios, { AxiosResponse } from "axios";
import { print, DocumentNode } from "graphql";
import { ALL_SERIES_SLUG } from "../constants";
import {
  allBrandsQuery,
  allBrandSlugsQuery,
  brandQuery,
  videoQuery,
  searchVideoQuery,
  seriesByIdQuery,
  brandVideosQuery,
} from "../queries";

type VariableDef = {
  limit?: number;
  skip?: number;
  programTypes?: string | string[];
  sort?: string;
  seriesId?: string | string[];
  ids?: string;
  guid?: string | string[];
  slug?: string;
  searchParam?: string;
  mediaType?: string;
};
type QueryDef = {
  usePost?: boolean;
  query: DocumentNode;
  variables?: VariableDef;
};

const graphQLRequest = axios.create({
  baseURL: process.env.REACT_APP_GRAPH_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  timeout: 10000,
});

export function onFulfilled(response: AxiosResponse) {
  if (response.data.errors !== undefined) {
    if (window.newrelic) {
      const [error] = response.data.errors;
      const custom = {
        graphql_error: error?.message || "missing message",
        status_code: error?.extensions?.code || "missing error code",
        response_URL: response.request?.responseURL,
      };
      window.newrelic.noticeError(new Error("GraphQL Error"), custom);
    }

    return Promise.reject(response.data.errors);
  }

  return response.data;
}

export function onRejected(error: any) {
  const { response } = error;
  if (window.newrelic) {
    const custom = {
      graphql_error:
        response?.data?.errors?.[0]?.message ||
        error.message ||
        "missing message",
      status_code: "REJECTED_RESPONSE",
    };
    window.newrelic.noticeError(error, custom);
  }
  return Promise.reject(error);
}

// Add a response interceptor
graphQLRequest.interceptors.response.use(onFulfilled, onRejected);

async function queryRequest({
  usePost = false,
  query,
  variables = {},
}: QueryDef): Promise<AxiosResponse> {
  const params = {
    query: print(query),
    variables,
  };

  if (usePost) {
    return graphQLRequest.post("/", params);
  }

  return graphQLRequest.get("/", {
    params,
  });
}

export async function getAllBrandSlugsRequest() {
  return queryRequest({
    query: allBrandSlugsQuery,
  });
}

export async function getAllBrandsRequest({
  limit = 10,
  skip = 0,
}): Promise<AxiosResponse> {
  return queryRequest({
    query: allBrandsQuery,
    variables: {
      limit,
      skip,
    },
  });
}

export async function getBrandRequest({
  brandId,
}: {
  brandId: string;
}): Promise<AxiosResponse> {
  return queryRequest({
    query: brandQuery,
    variables: {
      ids: brandId,
    },
  });
}

export async function getSeriesByIdRequest({
  guid,
  limit = 1,
  skip = 0,
  sort = "TITLE",
}: {
  guid: string | string[];
  limit?: number;
  skip?: number;
  sort?: string;
}): Promise<AxiosResponse> {
  const variables: VariableDef = {
    programTypes: "SERIES",
    limit,
    skip,
    sort,
  };
  if (guid) {
    variables.guid = guid;
  }
  return queryRequest({
    query: seriesByIdQuery,
    variables,
  });
}

export async function getVideosBySeriesIdRequest({
  limit = 1,
  skip = 0,
  programTypes = ["CLIP", "EPISODE"],
  sort = "ADDED",
  seriesId,
  live = false,
}: {
  limit: number;
  skip: number;
  programTypes: string | string[];
  sort: string;
  seriesId: string | string[];
  live?: boolean;
}): Promise<AxiosResponse> {
  const variables: VariableDef = {
    limit,
    skip,
    programTypes,
    sort,
  };
  if (seriesId !== ALL_SERIES_SLUG) {
    variables.seriesId = seriesId;
  }
  if (live) {
    variables.mediaType = "LIVE";
  }
  return queryRequest({
    query: videoQuery,
    variables,
  });
}

export async function getBrandVideosRequest({
  limit = 1,
  skip = 0,
  programTypes = ["CLIP", "EPISODE"],
  sort = "ADDED",
  seriesIds = [],
  live = false,
}: {
  limit: number;
  skip: number;
  programTypes: string | string[];
  sort: string;
  seriesIds: string[];
  live?: boolean;
}): Promise<AxiosResponse> {
  const variables: VariableDef = {
    limit,
    skip,
    programTypes,
    sort,
  };
  if (live) {
    variables.mediaType = "LIVE";
  }
  return queryRequest({
    usePost: true,
    query: brandVideosQuery(seriesIds),
    variables,
  });
}

export async function getVideosByGuidsRequest({
  sort = "ADDED",
  programTypes = ["CLIP", "EPISODE"],
  guid,
  skip = 0,
  limit = 50,
}: {
  sort: string;
  programTypes: string | string[];
  guid: string | string[];
  limit: number;
  skip: number;
}): Promise<AxiosResponse> {
  return queryRequest({
    usePost: true,
    query: videoQuery,
    variables: {
      sort,
      programTypes,
      guid,
      skip,
      limit,
    },
  });
}

export async function searchProgramsRequest({
  limit = 1,
  skip = 0,
  programTypes = ["CLIP", "EPISODE"],
  sort = "ADDED",
  searchParam,
  live = false,
}: {
  limit: number;
  skip: number;
  programTypes: string | string[];
  sort: string;
  searchParam: string;
  live?: boolean;
}): Promise<AxiosResponse> {
  const variables: VariableDef = {
    limit,
    skip,
    programTypes,
    sort,
    searchParam,
  };
  if (live) {
    variables.mediaType = "LIVE";
  }
  return queryRequest({
    query: searchVideoQuery,
    variables,
  });
}
