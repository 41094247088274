import React from "react";
import { useLocation } from "react-router-dom";
import { Toolbar } from "@material-ui/core";
import { TaqButton } from "../../TaqComponents";

function View({
  slug,
  embedlink,
  title,
  onCopy,
}: {
  slug: string;
  embedlink: string;
  title: string;
  onCopy: () => void;
}) {
  const { search } = useLocation();
  const [iframeUrl] = React.useState<string>(() => {
    const queryParams = new URLSearchParams(search);
    const embedEnv = queryParams.get("embedEnv");
    if (!embedEnv) return embedlink;
    return embedlink.replace("embed.kijk.nl", `embed.${embedEnv}.kijk.nl`);
  });

  return (
    <>
      <iframe
        data-testid="video-iframe"
        title={title}
        width="100%"
        height="283"
        src={iframeUrl}
        frameBorder="0"
        allowFullScreen={true}
        scrolling="no"
        allow="encrypted-media; autoplay; fullscreen; picture-in-picture"
        style={{ flexGrow: 1 }}
      />

      <Toolbar>
        <TaqButton
          component="button"
          onClick={onCopy}
          eventLabel={slug}
          eventCategory="videos"
          eventName="videos-modal-Video Id"
        >
          Video ID
        </TaqButton>
      </Toolbar>
    </>
  );
}

export default View;
