import gql from "graphql-tag";

export const seriesByIdQuery = gql`
  query($guid: [String], $programTypes: [ProgramType!]!, $limit: Int, $skip: Int, $sort: ProgramSortKey!) {
    programs(guid: $guid, programTypes: $programTypes, limit: $limit, skip: $skip, sort: $sort) {
      series:items {
        slug
        type
        id
        title
        imageMedia {
          url
          type
          label
        }
      }
    }
  }
`;