import { isEmpty } from "./isEmpty";
import {
  // getVideosByBrandRequest,
  getVideosBySeriesIdRequest,
  searchProgramsRequest,
  getVideosByGuidsRequest,
} from "./graphql";
import { VideoList, Video } from "../models";
import { SORT_OPTIONS } from "../constants";
import getLimit from "./getLimit";
import { getBrandSlug } from "./brand";
import { getProgramTypes } from "./getProgramType";

interface getVideosBySeriesIdProps {
  seriesId: string;
  sort?: string;
  slug?: string;
  programType?: string;
}

interface getVideosByBrandsProps {
  guid?: string[];
  sort?: string;
  programType?: string;
}

export async function getVideoBySeriesId({ seriesId, sort = "", programType = "" }: getVideosBySeriesIdProps) {
  if (!seriesId) {
    return null;
  }
  const sortType = SORT_OPTIONS.find(({ key }) => key === sort.toUpperCase()) || SORT_OPTIONS[0];

  const programTypes = getProgramTypes({ programType });

  const response = await getVideosBySeriesIdRequest({
    limit: 1,
    skip: 0,
    programTypes: programTypes.value,
    sort: sortType.key,
    seriesId,
  });

  if (isEmpty(response.data.programs?.items)) {
    return null;
  }
  return new Video(response.data.programs.items[0]);
}

export async function getVideosBySeriesId(
  querykey: string,
  { sort = "", programType = "", seriesId, slug }: getVideosBySeriesIdProps,
  skip = 0,
): Promise<{
  videos: Video[];
  nextSkip: number;
  moreItemsExist: boolean;
  totalResults: number;
}> {
  const limit = getLimit();

  const sortType = SORT_OPTIONS.find(({ key }) => key === sort.toUpperCase()) || SORT_OPTIONS[0];

  const programTypes = getProgramTypes({ programType });

  let response;
  if (seriesId) {
    response = await getVideosBySeriesIdRequest({
      limit,
      programTypes: programTypes.value,
      seriesId,
      skip,
      sort: sortType.key,
      live: !!programTypes.isLive,
    });
  } else if (slug) {
    response = await searchProgramsRequest({
      limit,
      programTypes: programTypes.value,
      searchParam: slug,
      skip,
      sort: sortType.key,
      live: !!programTypes.isLive,
    });
  } else {
    return {
      totalResults: 0,
      videos: [],
      nextSkip: skip + limit,
      moreItemsExist: false,
    };
  }

  if (isEmpty(response.data.programs?.items)) {
    return {
      totalResults: 0,
      videos: [],
      nextSkip: skip + limit,
      moreItemsExist: false,
    };
  }
  // Fetch Brand SLugs
  const videos = await Promise.all(
    new VideoList(response.data.programs).items.map(async (video) => {
      video.brandSlug = await getBrandSlug(video.series?.id);
      return video;
    }),
  );

  return {
    totalResults: response.data.programs.totalResults,
    videos,
    nextSkip: skip + limit,
    moreItemsExist: response.data.programs.items.length >= limit,
  };
}

export async function getVideosByBrands(
  querykey: string,
  { sort = "", programType = "", guid = [] }: getVideosByBrandsProps,
  skip = 0,
): Promise<{
  videos: Video[];
  nextSkip: number;
  moreItemsExist: boolean;
  totalResults: number;
}> {
  const limit = getLimit();
  const sortType = SORT_OPTIONS.find(({ key }) => key === sort.toUpperCase()) || SORT_OPTIONS[0];

  const programTypes = getProgramTypes({ programType });

  if (isEmpty(guid)) {
    return {
      totalResults: 0,
      videos: [],
      nextSkip: skip + limit,
      moreItemsExist: false,
    };
  }

  const { data } = await getVideosByGuidsRequest({
    guid,
    sort: sortType.key,
    programTypes: programTypes.value,
    limit,
    skip,
  });
  const moreItemsExist = data.programs.items.length >= limit;
  return {
    totalResults: data.programs.totalResults,
    videos: new VideoList(data.programs).items,
    nextSkip: skip + limit,
    moreItemsExist,
  };
}

export async function getVideoByGuid(
  _key: string,
  { guid, programType }: { guid: string; programType: string },
): Promise<Video | null> {
  const programTypes = getProgramTypes({ programType });

  const { data } = await getVideosByGuidsRequest({
    guid,
    sort: "ADDED",
    programTypes: programTypes.value,
    limit: 1,
    skip: 0,
  });
  if (data.programs.items.length) {
    return new Video(data.programs.items[0]);
  }

  return null;
}
