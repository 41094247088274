import { PROGRAM_TYPE_OPTIONS } from "../constants";

interface getProgramTypesProps {
  programType: string;
}

export function getProgramTypes({ programType }: getProgramTypesProps): IProgamType {
  return (
    PROGRAM_TYPE_OPTIONS.find(({ key, alias }) =>
      [key, ...alias].includes(`${programType}`)
    ) || PROGRAM_TYPE_OPTIONS[0] // all
  );
}
