import React from "react";
import { Chip, ChipProps, ChipTypeMap } from "@material-ui/core";

interface TaqChipProps {
  eventName?: string;
  eventCategory?: string;
  eventLabel?: string;
}
export const TaqChip = <
  D extends React.ElementType = ChipTypeMap["defaultComponent"],
  P = {}
>(
  props: ChipProps<D, P> & TaqChipProps
) => {
  const { eventName, eventCategory, eventLabel, ...otherProps } = props;
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    (window as any).Taq.push([
      "track",
      "link",
      {
        event_name: eventName || null,
        event_category: eventCategory || "event",
        event_action: "click",
        event_label: eventLabel || props.label,
      },
    ]);
  };
  return <Chip onClickCapture={handleClick} {...otherProps} />;
};

export default TaqChip;
