import { GraphqlProgram } from "./graphql.model";

export class Program {
  title?: string;
  slug?: string;
  type?: string;
  id = "";
  description?: string;
  link!: string;
  guid!: string;
  genres: string[] = [];
  originalLandscapeImage?: string;

  constructor(data: GraphqlProgram = {}) {
    if (data.title || data.series?.title) {
      this.title = data.title || data.series?.title;
    }

    if (data.slug) {
      this.slug = data.slug;
    }

    if (data.description) {
      this.description = data.description;
    }

    if (data.displayGenre) {
      this.genres = [data.displayGenre];
    }
    if (data.type) {
      this.type = data.type;
    }
    if (data.id) {
      this.id = data.id;
    }
    if (data.guid) {
      this.guid = data.guid;
      this.link = `https://www.kijk.nl/video/${data.guid}`;
    }

    if (data.title) {
      this.title = data.title;
    }

    const videoImage = data.imageMedia?.find((image) =>
      image.label?.includes("landscape")
    );

    if (videoImage) {
      this.originalLandscapeImage = videoImage.url;
    }
  }
}
