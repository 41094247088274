import React from "react";
import {
  Box,
  Container,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import RadioError from "./RadioError";
import { TaqLink } from "../TaqComponents";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: "center",
  },
  links: {
    "& > *": {
      display: "inline-block",
    },
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));
interface UnknownErrorProps {
  message?: string;
}

function UnknownError({ message }: UnknownErrorProps) {
  const classes = useStyles();
  const timestamp = Math.round(new Date().getTime() / 1000);
  return (
    <Container data-testid="UnknownError-mock" maxWidth="md" className={classes.root}>
      <Box my={10}>
        <RadioError />
        <Typography variant="h2" paragraph>
          {message || "Oeps... er ging iets mis."}
        </Typography>
        <Typography variant="body1" paragraph>
          Hier zijn wat suggesties om je op weg te helpen:
        </Typography>
        <div className={classes.links}>
          <TaqLink
            href={`/?preventCache=${timestamp}`}
            color="secondary"
            eventCategory="error"
          >
            Brands
          </TaqLink>
          <TaqLink
            href={`/series-overview?preventCache=${timestamp}`}
            color="secondary"
            eventCategory="error"
          >
            Alle programm's
          </TaqLink>
          <TaqLink
            href={`/video-feed?preventCache=${timestamp}`}
            color="secondary"
            eventCategory="error"
          >
            Documentatie
          </TaqLink>
        </div>
      </Box>
    </Container>
  );
}

export default UnknownError;
