import { IMAGE_PREFIX, IMAGE_PREFIX_PORTAIT } from "../constants";
import { GraphqlProgram, GraphqlSeries } from "./graphql.model";
import { Program } from "./program.model";
import { Video } from "./video.model";

export class Series extends Program {
  type = "SERIES";
  link = "";
  image? = `${IMAGE_PREFIX}https://kijk.nl/static/images/default_editoriallandscape.png`;
  imagePortrait? = `${IMAGE_PREFIX_PORTAIT}https://kijk.nl/static/images/default_editorialportrait.png`;
  video: Video | null = null;

  constructor(
    data: GraphqlProgram = {},
    series: GraphqlSeries | undefined = {}
  ) {
    super(data);
    if (series.id) {
      this.id = series.id;
    }

    if (series.id) {
      this.id = series.id;
    }

    if (series.title) {
      this.title = series.title;
    }

    if (series.slug) {
      this.slug = series.slug;
    }

    if (series.description) {
      this.description = series.description;
    }

    const image =
      series.imageMedia?.find(({ label }) => label.includes("landscape")) ||
      data.imageMedia?.find(({ label }) => label.includes("landscape"));

    if (image) {
      this.image = `${IMAGE_PREFIX}${image.url}`;
    }

    const imagePortrait =
      series.imageMedia?.find(({ label }) => label.includes("portrait")) ||
      data.imageMedia?.find(({ label }) => label.includes("portrait"));

    if (imagePortrait) {
      this.imagePortrait = `${IMAGE_PREFIX_PORTAIT}${imagePortrait.url}`;
    }
  }
}
