import gql from "graphql-tag";

export const allBrandsQuery = gql`
  query AllBrands($limit: Int, $skip: Int) {
    brands(limit: $limit, skip: $skip) {
      id
      title
      slug
      description
      headerMedia {
        url
        title
      }
    }
  }
`;

export const allBrandSlugsQuery = gql`
  query AllBrandSlugs {
    brands(limit: 100, skip: 0) {
      id
      slug
    }
  }
`;

export const brandQuery = gql`
  query($ids: [String]!) {
    brands(ids: $ids) {
      id
      title
      slug
      headerMedia {
        url
        title
      }
    }
  }
`;

export const brandVideosQuery = (seriesIds: string[]) => gql`
query ($mediaType: MediaTypeFilter, $limit: Int!, $skip: Int!, $programTypes: [ProgramType]!) {
  ${seriesIds.map(
    (id, index) =>
      `series${index}: programs(mediaType: $mediaType, limit: $limit, skip: $skip, programTypes: $programTypes, seriesId: "${id}") {
      items { guid, epgDate, added, title }
    }`
  )}
}`;
