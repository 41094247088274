import gql from "graphql-tag";

export const videoQuery = gql`
  query(
    $mediaType: MediaTypeFilter
    $programTypes: [ProgramType]!
    $skip: Int!
    $limit: Int!
    $sort: ProgramSortKey!
    $seriesId: String
    $guid: [String]
  ) {
    programs(
      mediaType: $mediaType
      programTypes: $programTypes
      skip: $skip
      limit: $limit
      sort: $sort
      seriesId: $seriesId
      guid: $guid
    ) {
      totalResults
      items {
        id
        guid
        title
        type
        slug
        description
        longDescription
        shortDescription
        added
        updated
        epgDate
        lastAirDateTime
        displayGenre
        sources {
          file
        }
        imageMedia {
          url
          type
          label
        }
        duration
        tvSeasonEpisodeNumber
        seasonNumber
        series {
          id
          title
          slug
          description
          longDescription
          shortDescription
          displayGenre
          imageMedia {
            url
            type
            label
          }
        }
        media {
          type
          availabilityState
          availableDate
        }
      }
    }
  }
`;

export const videosByBrandQuery = gql`
  query(
    $programTypes: [ProgramType]!
    $skip: Int!
    $limit: Int!
    $sort: ProgramSortKey!
  ) {
    programs(
      programTypes: $programTypes
      skip: $skip
      limit: $limit
      sort: $sort
    ) {
      totalResults
      items {
        id
        guid
        series {
          id
        }
      }
    }
  }
`;

export const searchVideoQuery = gql`
  query(
    $mediaType: MediaTypeFilter
    $programTypes: [ProgramType]!
    $skip: Int!
    $limit: Int!
    $sort: ProgramSortKey!
    $searchParam: String!
  ) {
    programs(
      mediaType: $mediaType
      programTypes: $programTypes
      skip: $skip
      limit: $limit
      sort: $sort
      searchParam: $searchParam
    ) {
      totalResults
      items {
        id
        guid
        title
        type
        slug
        description
        longDescription
        shortDescription
        added
        updated
        epgDate
        lastAirDateTime
        displayGenre
        sources {
          file
        }
        imageMedia {
          url
          type
          label
        }
        duration
        tvSeasonEpisodeNumber
        seasonNumber
        series {
          id
          title
          slug
          description
          longDescription
          shortDescription
          displayGenre
          imageMedia {
            url
            type
            label
          }
        }
        media {
          type
        }
      }
    }
  }
`;
