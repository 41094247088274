import React, { Component } from "react";
import UnknownError from "../UnknownError";
import { RouteComponentProps, withRouter } from "react-router-dom";

class ErrorBoundary extends Component<RouteComponentProps> {
  state = {
    hasError: false,
  };

  componentDidUpdate(prevProps: RouteComponentProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error: Error | null, info: object) {
    this.setState({ hasError: true });
    if (window.newrelic) {
      window.newrelic.noticeError(error, info);
    }
  }

  render() {
    if (this.state.hasError) {
      return <UnknownError />;
    }
    return this.props.children;
  }
}
export default withRouter(ErrorBoundary);
