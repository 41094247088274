import React from "react";
import { ButtonGroup } from "@material-ui/core";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";
import { TaqChip } from "../TaqComponents";
import { getBrandSeries, isEmpty } from "../utils";

interface SeriesSwitchProps {
  brandSlug: string;
}
function SeriesSwitch({ brandSlug }: SeriesSwitchProps) {
  const { data: series } = useQuery<{ slug: string; id: string }[]>(
    [
      "brandSeries",
      {
        brandSlug,
      },
    ],
    getBrandSeries
  );

  if (isEmpty(series)) {
    return <div style={{ height: 62 }} />;
  }

  return (
    <ButtonGroup
      color="secondary"
      aria-label="outlined primary button group"
      fullWidth
      style={{
        maxWidth: "100%",
        overflow: "scroll",
        paddingBottom: 10,
        marginBottom: 10,
      }}
    >
      {series?.map(({ id, slug }) => (
        <TaqChip
          key={id}
          color="secondary"
          clickable
          label={slug}
          component={NavLink}
          to={`/video/html/added/all-channels/all/${slug}/`}
          style={{ minWidth: "auto" }}
        />
      ))}
    </ButtonGroup>
  );
}

export default SeriesSwitch;
