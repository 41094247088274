import React from "react";
import { createTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import CeraProRegularWoff2 from "./fonts/CeraPro-Regular.woff2";
import CeraProRegularEot from "./fonts/CeraPro-Regular.eot";
import CeraProRegularWoff from "./fonts/CeraPro-Regular.woff";
import CeraProRegularTtf from "./fonts/CeraPro-Regular.ttf";

import CeraProBoldWoff2 from "./fonts/CeraPro-Bold.woff2";
import CeraProBoldEot from "./fonts/CeraPro-Bold.eot";
import CeraProBoldWoff from "./fonts/CeraPro-Bold.woff";
import CeraProBoldTtf from "./fonts/CeraPro-Bold.ttf";

const CeraRegular = {
  fontFamily: "Cera",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    url('${CeraProRegularEot}'),
    url('${CeraProRegularEot}?#iefix') format('embedded-opentype'),
    url('${CeraProRegularWoff2}') format('woff2'),
    url('${CeraProRegularWoff}') format('woff'),
    url('${CeraProRegularTtf}') format('truetype');
  `,
};

const CeraBold = {
  fontFamily: "Cera",
  fontStyle: "bold",
  fontDisplay: "swap",
  fontWeight: 500,
  src: `
    url('${CeraProBoldEot}'),
    url('${CeraProBoldEot}') format('embedded-opentype'),
    url('${CeraProBoldWoff2}') format('woff2'),
    url('${CeraProBoldWoff}') format('woff'),
    url('${CeraProBoldTtf}') format('truetype');
  `,
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#fafafa",
    },
    secondary: {
      main: "#212121",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        // @ts-ignore
        "@font-face": [CeraRegular, CeraBold],
        "#ot-sdk-btn-floating": {
          display: "none !important",
        },
      },
    },
    MuiTypography: {
      h1: {
        fontSize: "40px",
      },
      h2: {
        fontSize: "30px",
      },
      h3: {
        fontSize: "20px",
      },
    },
    MuiInputLabel: {
      root: {
        "&$focused": {
          color: "#212121",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        "&$focused $notchedOutline": {
          borderColor: "#212121",
          borderWidth: 1,
        },
      },
    },
  },
  typography: {
    fontFamily: "Cera, Helvetica Neue, Helvetica, sans-serif",
  },
});

const useStyles = makeStyles(() => ({
  "@global": {
    body: {
      margin: 0,
    },
  },
}));

function Palette({ children }: { children: React.ReactNode }) {
  useStyles();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

export default Palette;
