import React from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import { Container, LinearProgress, Typography, Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { getVideosBySeriesId, getSeriesBySlug, isEmpty, getVideoByGuid } from "../../utils";
import { Video, Series, RouteParams } from "../../models";
import VideoCard from "../../VideoCard";
import Settings from "../../Settings";
import InfinityScroll from "../../InfinityScroll";
import UnknownError from "../../UnknownError";

function ProgramPage() {
  const { sort, programType, slug } = useParams<RouteParams>();
  const {
    data: series,
    error: seriesError,
    isFetching: isFetchingSeries,
  } = useQuery<Series | null>(["series", { slug }], getSeriesBySlug, {
    refetchOnWindowFocus: false,
  });
  const { data: video } = useQuery<Video | null>(["series", { guid: slug, programType }], getVideoByGuid, {
    refetchOnWindowFocus: false,
  });

  const { data, error, isFetching, isFetchingMore, fetchMore, canFetchMore, refetch } = useInfiniteQuery<{
    moreItemsExist: boolean;
    nextSkip: number;
    videos: Video[];
    totalResults: number;
  }>(
    [
      "videos",
      {
        seriesId: !isFetchingSeries && series?.id,
        sort,
        slug: !isFetchingSeries && slug,
        programType,
      },
    ],
    getVideosBySeriesId,
    {
      getFetchMore: (lastGroup) => lastGroup.moreItemsExist && lastGroup.nextSkip,
      refetchOnWindowFocus: false,
    },
  );

  if (video) {
    return (
      <>
        <div style={{ minHeight: 20 }}></div>
        <Container>
          <Settings onRefetch={refetch} />
          <Typography variant="h1" paragraph>
            {video.series?.title && `${video.series.title} | `}
            {video.title}
          </Typography>
          <Grid container spacing={3}>
            <Grid key={video.guid} item xs={12} sm={6} md={4} lg={3}>
              <VideoCard video={video} />
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }

  const [firstResponse] = data || [];
  if (error || seriesError) {
    return <UnknownError />;
  }
  return (
    <>
      <div style={{ minHeight: 20 }}>
        {(isFetching || isFetchingSeries) && <LinearProgress data-testid="liniar-progress" color="secondary" />}
      </div>
      <Container>
        <Settings onRefetch={refetch} />
        <Typography variant="h1" paragraph>
          {series && series.title}
          {!series && !isFetchingSeries && firstResponse && `${firstResponse.totalResults} search results for ${slug}`}
        </Typography>
        <Grid container spacing={3}>
          {data?.map(({ videos, nextSkip }) => (
            <React.Fragment key={nextSkip}>
              {videos.map((video) => (
                <Grid key={video.guid} item xs={12} sm={6} md={4} lg={3}>
                  <VideoCard video={video} />
                </Grid>
              ))}
            </React.Fragment>
          ))}
        </Grid>
        <InfinityScroll
          fetchMore={fetchMore}
          canFetchMore={!!canFetchMore}
          isFetching={isFetchingSeries || isFetching}
          isFetchingMore={!!isFetchingMore}
          showLoading={isEmpty(firstResponse?.videos)}
        />
      </Container>
    </>
  );
}

export default ProgramPage;
