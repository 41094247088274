import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import ErrorBoundary from "./ErrorBoundary";
import Palette from "./Palette";
import { ROUTES } from "./constants";
import { onHashChange } from "./utils";

function App() {
  useEffect(() => {
    window.addEventListener("hashchange", onHashChange);
  }, []);
  return (
    <Palette>
      <Router>
        <Header />
        <ErrorBoundary>
          <div style={{ minHeight: 600 }}>
            <Switch>
              {ROUTES.map((route) => (
                <Route {...route} key={route.key} />
              ))}
            </Switch>
          </div>
        </ErrorBoundary>
        <Footer />
      </Router>
    </Palette>
  );
}

export default App;
