import React from "react";
import { useInfiniteQuery } from "react-query";
import {
  Box,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { getAllBrands, isEmpty } from "../../utils";
import { Brand } from "../../models";
import BrandCard from "./BrandCard";
import InfinityScroll from "../../InfinityScroll";
import UnknownError from "../../UnknownError";

const placeholders: { nextSkip: number; brands: Brand[] }[] = [
  {
    brands: Array.from({ length: 8 }, (v, i) => ({
      id: `${i}`,
    })),
    nextSkip: 0,
  },
];

function BrandOverview() {
  const {
    data,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
    error,
  } = useInfiniteQuery<{
    moreItemsExist: boolean;
    nextSkip: number;
    brands: Brand[];
  }>("allBrands", getAllBrands, {
    getFetchMore: (lastGroup) => lastGroup.moreItemsExist && lastGroup.nextSkip,
    refetchOnWindowFocus: false,
  });
  const [firstResponse] = data || [];
  if (error || (!isFetching && isEmpty(firstResponse.brands))) {
    return <UnknownError />;
  }

  return (
    <>
      <div style={{ minHeight: 20 }}>
        {(isFetching || isFetchingMore) && (
          <LinearProgress data-testid="liniar-progress" color="secondary" />
        )}
      </div>
      <Container>
        <Typography variant="h1">Alle brands</Typography>
        <Box my={2}>
          <Grid container spacing={3}>
            {(data || placeholders).map(({ brands, nextSkip }) => (
              <React.Fragment key={nextSkip}>
                {brands.map((brand: Brand) => (
                  <Grid key={brand.id} item xs={12} sm={12} md={6} lg={4}>
                    <BrandCard {...brand} />
                  </Grid>
                ))}
              </React.Fragment>
            ))}
          </Grid>
          <InfinityScroll
            fetchMore={fetchMore}
            canFetchMore={!!canFetchMore}
            isFetching={isFetching}
            isFetchingMore={!!isFetchingMore}
          />
        </Box>
      </Container>
    </>
  );
}

export default BrandOverview;
