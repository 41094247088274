import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import ReactMarkdown from "react-markdown";
import markupFile from "./DocumentationFeed.md";

const useStyles = makeStyles(() => ({
  container: {
    fontSize: "1rem",
    "& pre": {
      whiteSpace: "pre-wrap",
      backgroundColor: " #e9ecef",
      padding: 10,
    },
    "& li": {
      marginBottom: 10,
    },
  },
}));

function DocumentationFeed() {
  const classes = useStyles();
  const [markup, setMarkup] = useState("");
  useEffect(() => {
    (async function loadMarkup() {
      const response = await fetch(markupFile);
      const text = await response.text();
      setMarkup(text);
    })();
  }, []);

  return (
    <Container maxWidth="md" className={classes.container}>
      {markup && <ReactMarkdown source={markup} />}
    </Container>
  );
}

export default DocumentationFeed;
