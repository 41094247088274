import React from "react";
import { Button, ButtonProps, ButtonTypeMap } from "@material-ui/core";

interface TaqButtonProps {
  eventName?: string;
  eventCategory?: string;
  eventLabel?: string;
}
export const TaqButton = <
  D extends React.ElementType = ButtonTypeMap["defaultComponent"],
  P = {}
>(
  props: ButtonProps<D, P> & TaqButtonProps
) => {
  const { eventName, eventCategory, eventLabel, ...otherProps } = props;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    (window as any).Taq.push([
      "track",
      "link",
      {
        event_name: eventName || null,
        event_category: eventCategory || "event",
        event_action: "click",
        event_label: eventLabel || (event.target as HTMLElement).textContent,
      },
    ]);
  };
  return <Button onClickCapture={handleClick} {...otherProps} />;
};

export default TaqButton;
