import {
  BrandOverview,
  SeriesOverview,
  DocumentationFeed,
  BrandPage,
  ProgramPage,
  NoMatch,
  ContentPlanner,
} from "../pages";

interface RouteInterface {
  key: string;
  path: string | string[];

  exact: boolean;
  component: React.FC;
}

export const ROUTES: RouteInterface[] = [
  {
    key: "brand-overview",
    path: "/",
    exact: true,
    component: BrandOverview,
  },
  {
    key: "series-overview",
    path: "/series-overview",
    exact: false,
    component: SeriesOverview,
  },
  {
    key: "documentation-feed",
    path: "/video-feed",
    exact: false,
    component: DocumentationFeed,
  },
  {
    key: "program",
    path: "/video/html/:sort/:brand/:programType/:slug/",
    exact: true,
    component: ProgramPage,
  },
  {
    key: "search",
    path: "/search/html/:sort/:brand/:programType/:slug/",
    exact: true,
    component: ProgramPage,
  },
  {
    key: "brand",
    path: [
      "/video/html/:sort/:brand/:programType/",
      "/video/html/:sort/:brand/",
    ],
    exact: true,
    component: BrandPage,
  },
  {
    key: "content-planner",
    path: "/content-planner",
    exact: true,
    component: ContentPlanner,
  },
  {
    key: "no-match",
    path: "*",
    exact: false,
    component: NoMatch,
  },
];
