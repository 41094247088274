import React from "react";

function MenuIcon() {
  return (
    <svg viewBox="0 0 24 24" height="24" width="24">
      <path
        fill="currentColor"
        d="M 3 18 h 18 v -2 H 3 v 2 Z m 0 -5 h 18 v -2 H 3 v 2 Z m 0 -7 v 2 h 18 V 6 H 3 Z"
      ></path>
    </svg>
  );
}

export default MenuIcon;
