export const CLIP = "CLIP";

export const EPISODE = "EPISODE";

export const PROGRAM_TYPE_OPTIONS: IProgamType[] = [
  { key: "all", alias: [], value: [CLIP, EPISODE], title: "Alles" },
  { key: "clip", alias: ["clips"], value: CLIP, title: "Fragmenten" },
  { key: "vod", alias: ["video", "episode", "episodes"], value: EPISODE, title: "Afleveringen" },
  {
    key: "live",
    alias: [],
    value: EPISODE,
    isLive: true,
    title: "Live",
  },
];
