import React from "react";
import { Box, Container, Typography } from "@material-ui/core";

function Footer() {
  return (
    <footer style={{ borderTop: ".1rem solid #d4d4d4", marginTop: 32 }}>
      <Container>
        <Box py={4}>
          <Typography variant="body1" paragraph>
          © 2021 <strong>Talpa Network</strong>
          </Typography>
        </Box>
      </Container>
    </footer>
  );
}

export default Footer;
