import React, { useState } from "react";
import {
  TextField,
  Toolbar,
  makeStyles,
  FormControlLabel,
  Switch,
  IconButton,
  Popover,
  Typography,
  Box,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { TaqButton } from "../../TaqComponents";
import { LockIcon } from "../../icons";
const EMBED_KEY = "EMBED_CUSTOM";
const DEFAULT_WIDTH = 500;
const DEFAULT_HEIGHT = 283;

const RATIO = 16 / 9;

type CustomValueType = "width" | "height" | "token" | "autoplay" | "ratioLocked";
interface CustomInput {
  key: CustomValueType;
  title: string;
  inputWidth: string | number;
}
const INPUTS: CustomInput[] = [
  { key: "token", title: "Token", inputWidth: "auto" },
  { key: "width", title: "width", inputWidth: 80 },
  { key: "ratioLocked", title: "Lock ratio", inputWidth: 80 },
  { key: "height", title: "height", inputWidth: 80 },
  { key: "autoplay", title: "autoplay", inputWidth: 80 },
];

const useStyles = makeStyles((theme) => ({
  textField: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  input: {
    padding: "10px 14px",
  },
  flex: {
    flexGrow: 1,
  },
  switch: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: green[800],
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: green[800],
    },
  },
  toolbar: {
    "&.MuiToolbar-root": {
      [theme.breakpoints.down("sm")]: {
        display: "inline-block",
      },
    },
  },
}));

function Embed({
  slug,
  copyToClipBoard,
  embedlink,
}: {
  slug: string;
  embedlink: string;
  copyToClipBoard: (text: string) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const classes = useStyles();
  const [customValues, setCustomValues] = useState<{
    height: number;
    width: number;
    token: string;
    autoplay: boolean;
    ratioLocked: boolean;
  }>(() => {
    const storedValue = localStorage.getItem(EMBED_KEY);
    if (storedValue) {
      return JSON.parse(storedValue);
    }
    return {
      height: DEFAULT_HEIGHT,
      width: DEFAULT_WIDTH,
      token: "",
      autoplay: true,
      ratioLocked: true,
    };
  });

  const { height, width, token, autoplay, ratioLocked } = customValues;

  const handleInputChange =
    (key: CustomValueType) => (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { value } = event.target;
      const isNumber = !Number.isNaN(+value);
      setCustomValues((prevState) => {
        const nextState = {
          ...prevState,
          [key]: value,
        };
        if (ratioLocked) {
          if (key === "height") nextState.width = isNumber ? Math.round(+value * RATIO) : DEFAULT_WIDTH;
          if (key === "width") nextState.height = isNumber ? Math.round(+value / RATIO) : DEFAULT_HEIGHT;
        }
        try {
          localStorage.setItem(EMBED_KEY, JSON.stringify(nextState));
        } catch (error) {
          console.error(`Couldn't store custom values`);
        }
        return nextState;
      });
    };

  const handleAutoplayChange = (
    _event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    checked: boolean
  ) => {
    setCustomValues((prevState) => {
      const nextState = {
        ...prevState,
        autoplay: checked,
      };
      try {
        localStorage.setItem(EMBED_KEY, JSON.stringify(nextState));
      } catch (error) {
        console.error(`Couldn't store custom values`);
      }
      return nextState;
    });
  };

  const handleRatioChange = () => {
    setCustomValues((prevState) => {
      const nextState = {
        ...prevState,
        ratioLocked: !prevState.ratioLocked,
      };
      try {
        localStorage.setItem(EMBED_KEY, JSON.stringify(nextState));
      } catch (error) {
        console.error(`Couldn't store custom values`);
      }
      return nextState;
    });
  };

  const handleReset = () => {
    setCustomValues({
      height: DEFAULT_HEIGHT,
      width: DEFAULT_WIDTH,
      token: "",
      autoplay: true,
      ratioLocked: true,
    });
    try {
      localStorage.removeItem(EMBED_KEY);
    } catch (error) {
      console.error(`Couldn't clear localStorage`);
    }
  };

  const embedCode = `<iframe width="${width}" height="${height}" src="${embedlink}&autoplay=${autoplay ? "1" : "0"}${
    token ? `&token=${token}` : ""
  }" frameborder="0" allowfullscreen="allowfullscreen" scrolling="no" class="sbsEmbed" allow="encrypted-media; autoplay; fullscreen; picture-in-picture" style="max-width: 100%"></iframe>`;

  return (
    <>
      <TextField
        id="filled-multiline-static"
        label="Embed"
        multiline
        rows={6}
        value={embedCode}
        variant="filled"
        InputProps={{ style: { height: "100%", flexGrow: 1 } }}
        style={{ width: "100%", flexGrow: 1 }}
      />
      <Toolbar className={classes.toolbar}>
        <TaqButton
          component="button"
          onClick={copyToClipBoard(embedCode)}
          eventLabel={slug}
          eventCategory="videos"
          eventName="videos-modal-Kopieer embed"
        >
          Kopieer embed
        </TaqButton>
        <div className={classes.flex} />
        {INPUTS.map(({ key, title, inputWidth }: CustomInput) => (
          <React.Fragment key={key}>
            {key === "autoplay" ? (
              <FormControlLabel
                control={<Switch checked={autoplay} className={classes.switch} onChange={handleAutoplayChange} />}
                label="autoplay"
              />
            ) : key === "ratioLocked" ? (
              <>
                <IconButton
                  color="primary"
                  className={classes.textField}
                  aria-label={ratioLocked ? "Unlock ratio" : "Lock ratio"}
                  onClick={handleRatioChange}
                  title="locked"
                  onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
                  onMouseLeave={() => setAnchorEl(null)}
                >
                  <LockIcon locked={ratioLocked} />
                </IconButton>
                <Popover
                  open={!!anchorEl}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  disableRestoreFocus
                  style={{ pointerEvents: "none" }}
                >
                  <Box m={2}>
                    <Typography>{ratioLocked ? "Ratio is locked to 16:9" : "Ratio unlocked"}</Typography>
                  </Box>
                </Popover>
              </>
            ) : (
              <TextField
                style={{ width: inputWidth }}
                className={classes.textField}
                label={title}
                type="text"
                inputProps={{ className: classes.input }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={customValues[key]}
                onChange={handleInputChange(key)}
              />
            )}
          </React.Fragment>
        ))}
        <TaqButton
          component="button"
          onClick={handleReset}
          eventLabel={slug}
          eventCategory="videos"
          eventName="videos-modal-Reset"
        >
          Reset
        </TaqButton>
      </Toolbar>
    </>
  );
}
export default Embed;
