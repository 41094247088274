import React from "react";
import { NavLink, useParams } from "react-router-dom";
import {
  Chip,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  createStyles,
  makeStyles,
  Typography,
  Theme,
  ButtonGroup,
  Popover,
} from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import { Video } from "../models";
import EmbedLinks from "./EmbedLinks";
import { TaqButton, TaqChip } from "../TaqComponents";
import { CLIP } from "../constants";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiChip-root": {
        margin: 1,
        maxWidth: "100%",
      },
      "& .MuiCardMedia-root": {
        backgroundPosition: "center",
        backgroundSize: "cover",
        position: "relative",
      },
      "& .MuiCardHeader-content .MuiTypography-root": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        lineClamp: 1,
        boxOrient: "vertical",
      },
    },
    liveLabel: {
      position: "absolute",
      bottom: theme.spacing(1),
      left: theme.spacing(2),
      zIndex: 1,
      backgroundColor: red[700],
      color: "white",
      "&.MuiChip-colorSecondary": {
        backgroundColor: grey[500],
      },
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
      background: theme.palette.grey[300],
    },
    description: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      lineClamp: 2,
      boxOrient: "vertical",
      minHeight: 40,
    },

    disclaimer: {
      color: "#000",
      textAlign: "center",
      fontSize: "14px",
      maxWidth: "100%",
      padding: "8px 6px",
      width: 380,
    },
  })
);

interface VideoCardProps {
  video: Video;
  showEmbed?: boolean;
}

const ImageButton: React.FC<{ video: Video }> = ({ video }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const classes = useStyles();
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <CardActions>
        <ButtonGroup fullWidth color="secondary" aria-label="outlined primary button group">
          <TaqButton
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            href={video.originalLandscapeImage}
            target="_blank"
            eventLabel={video.originalLandscapeImage}
            eventCategory="videos"
            eventName="videos-Open Image"
          >
            Open Image
          </TaqButton>
        </ButtonGroup>
      </CardActions>
      <Popover
        id="mouse-over-popover"
        style={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography
          className={classes.disclaimer}
        >{`Deze afbeelding is auteursrechtelijk beschermd. Publicaties buiten de Talpa Network player is alleen toegestaan na goedkeuring Talpa Network.`}</Typography>
      </Popover>
    </>
  );
};

function VideoCard({ video }: VideoCardProps) {
  const { sort } = useParams<{ sort: string }>();
  const classes = useStyles();

  const { series } = video;
  return (
    <Card className={classes.root}>
      <CardHeader title={series?.title} subheader={video.title} />
      <CardMedia className={classes.media} image={video.videoImage} title={series?.title}>
        {video.isLive && (
          <Chip
            className={classes.liveLabel}
            color={video.isCurrentlyLive ? "primary" : "secondary"}
            variant="default"
            label="LIVE"
          />
        )}
      </CardMedia>
      <CardContent>
        <Typography className={classes.description} variant="body2" color="textSecondary" component="p" paragraph>
          {video.description}
        </Typography>
        <div>
          {video.brandSlug && (
            <TaqChip
              color="secondary"
              clickable
              label={video.brandSlug}
              component={NavLink}
              to={`/video/html/added/${video.brandSlug}/all/`}
            />
          )}
          {series && (
            <TaqChip
              color="secondary"
              clickable
              label={series.title}
              component={NavLink}
              to={`/video/html/added/all-channels/all/${series.slug}/`}
            />
          )}
          {video.episodeNumber && <Chip variant="outlined" label={video.episodeNumber} />}
          <Chip variant="outlined" label={video.type === CLIP ? "Fragment" : "Aflevering"} />
          {sort.match(/pubdate/i) ? (
            <Chip variant="outlined" label={`Uitzending van ${video.transmissionDate}`} />
          ) : (
            <Chip variant="outlined" label={`Toegevoegd op ${video.pubDate}`} />
          )}

          {video.unavailable && video.availableDate && (
            <Chip variant="default" label={`Beschikbaar vanaf ${video.availableDate}`} />
          )}
        </div>
      </CardContent>
      <CardActions disableSpacing>
        <EmbedLinks
          slug={video.slug}
          seriesTitle={series?.title}
          embedlink={video.embedlink}
          title={video.title}
          guid={video.guid}
          originalLandscapeImage={video.originalLandscapeImage}
        />
      </CardActions>
      {video.originalLandscapeImage && <ImageButton video={video} />}
      {/* <CardContent style={{ paddingTop: 8 }}>
        <Typography className={classes.disclaimer} variant="body2" color="textSecondary" component="p"></Typography>
      </CardContent> */}
    </Card>
  );
}

export default VideoCard;
