import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  createStyles,
  ButtonGroup,
  makeStyles,
  Typography,
  Theme,
} from "@material-ui/core";

import React from "react";

import { Link } from "react-router-dom";
import { Brand } from "../../../models";
import { TaqButton } from "../../../TaqComponents";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiCardMedia-root": {
        backgroundPosition: "center",
        backgroundSize: "cover",
      },
      "& .MuiCardActions-root": {
        minHeight: 52,
      },
      "& .MuiCardHeader-root": {
        minHeight: 62,
      },
    },
    title: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      lineClamp: 1,
      boxOrient: "vertical",
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
      background: theme.palette.grey[300],
    },
    description: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      lineClamp: 2,
      boxOrient: "vertical",
      minHeight: 40,
    },
  })
);

function BrandCard(brand: Brand) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader
        classes={{
          title: classes.title,
        }}
        title={brand.title}
      />
      {brand.image ? (
        <CardMedia
          className={classes.media}
          image={brand.image}
          title={brand.title}
        />
      ) : (
        <div className={classes.media} />
      )}

      <CardContent>
        <Typography
          className={classes.description}
          variant="body2"
          color="textSecondary"
          component="p"
        >
          {brand.description}
        </Typography>
      </CardContent>

      <CardActions disableSpacing>
        {brand.slug && (
          <ButtonGroup
            color="secondary"
            aria-label="outlined primary button group"
            fullWidth
          >
            <TaqButton
              component={Link}
              to={`/video/html/added/${brand.slug}/all/`}
              eventLabel={brand.slug}
              eventCategory="brand"
              eventName="brand-Alles"
            >
              Alles
            </TaqButton>
            <TaqButton
              component={Link}
              to={`/video/html/added/${brand.slug}/clip/`}
              eventLabel={brand.slug}
              eventCategory="brand"
              eventName="brand-Fragmenten"
            >
              Fragmenten
            </TaqButton>
            <TaqButton
              component={Link}
              to={`/video/html/added/${brand.slug}/vod/`}
              eventLabel={brand.slug}
              eventCategory="brand"
              eventName="brand-Afleveringen"
            >
              Afleveringen
            </TaqButton>
          </ButtonGroup>
        )}
      </CardActions>
    </Card>
  );
}

export default BrandCard;
