import React from "react";
import { Box, Button, CircularProgress } from "@material-ui/core";
import { useIntersectionObserver } from "../hooks";

interface InfinityScrollProps {
  fetchMore: () => void;
  canFetchMore?: boolean;
  isFetching?: boolean;
  isFetchingMore?: boolean;
  showLoading?: boolean;
}
function InfinityScroll({
  fetchMore,
  canFetchMore = false,
  isFetching = false,
  isFetchingMore = false,
  showLoading = false,
}: InfinityScrollProps) {
  const loadMoreButtonRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

  const handleClick = () => {
    fetchMore();
  };
  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchMore,
    enabled: canFetchMore || false,
  });
  return (
    <>
      {(isFetching || isFetchingMore) && showLoading && (
        <Box my={10} display="flex" justifyContent="center">
          <CircularProgress data-testid="spinner" color="secondary" size={40} />
        </Box>
      )}
      <Box my={3}>
        <Button
          ref={loadMoreButtonRef}
          onClick={handleClick}
          disabled={!canFetchMore || !!isFetchingMore}
          fullWidth
        >
          {isFetchingMore
            ? "Bezig met meer laden..."
            : isFetching
            ? "Bezig met laden..."
            : canFetchMore
            ? "Meer laden"
            : "Niets meer te laden"}
        </Button>
      </Box>
    </>
  );
}

export default InfinityScroll;
