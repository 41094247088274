export function copyToClipboard(text: string) {
  const clipboardElement = document.createElement("textarea");
  clipboardElement.textContent = text;
  clipboardElement.setAttribute(
    "style",
    "position:absolute; z-index: 1301;left:-9999px"
  );
  document.body.appendChild(clipboardElement);

  clipboardElement.select();

  document.execCommand("copy");
  document.body.removeChild(clipboardElement);
}
