import React from "react";
import {
  Card,
  CardContent,
  // CardHeader,
  CardMedia,
  createStyles,
  makeStyles,
  Typography,
  Theme,
} from "@material-ui/core";
import { Series } from "../models";
import { NavLink } from "react-router-dom";
import { TaqLink } from "../TaqComponents";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiChip-root": {
        margin: 1,
        maxWidth: "100%",
      },
      "& .MuiCardMedia-root": {
        backgroundPosition: "center",
        backgroundSize: "cover",
      },
      "& .MuiCardHeader-content .MuiTypography-root": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        lineClamp: 1,
        boxOrient: "vertical",
      },
    },
    media: {
      height: 0,
      paddingTop: "150%", // 2:3
      background: theme.palette.grey[300],
    },
    title: {
      fontSize: "18px",
      fontWeight: 500,
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      lineClamp: 2,
      boxOrient: "vertical",
      minHeight: 40,
    },
  })
);

interface SeriesCardProps {
  series: Series;
}

function SeriesCard({ series }: SeriesCardProps) {
  const classes = useStyles();

  return (
    <TaqLink
      component={NavLink}
      to={`/video/html/added/all-channels/all/${series?.slug}/`}
      data-testid="series-card"
      eventLabel={series.slug}
      eventCategory="series"
      eventName="series-click"
    >
      <Card className={classes.root}>
        <CardMedia
          className={classes.media}
          image={series.imagePortrait}
          title={series?.title}
        />
        <CardContent>
          <Typography
            className={classes.title}
            variant="h3"
            color="textPrimary"
          >
            {series.title}
          </Typography>
        </CardContent>
      </Card>
    </TaqLink>
  );
}

export default SeriesCard;
