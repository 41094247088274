import { Brand, GraphqlBrand, GraphqlProgram, RouteParams } from "../models";
import { getAllBrandsRequest, getAllBrandSlugsRequest, getBrandRequest, getBrandVideosRequest } from "./graphql";
import { isEmpty } from "./isEmpty";
import getLimit from "./getLimit";
import { fetchFromFeedApi } from "./feedApi";
import { SORT_OPTIONS } from "../constants";
import { sortVideos } from "./sortVideos";
import { getProgramTypes } from "./getProgramType";

export async function getBrandIdBySlug(slug?: string): Promise<string | null> {
  const response = await getAllBrandSlugsRequest();

  if (isEmpty(response.data?.brands)) {
    return null;
  }
  return response.data.brands.find((brand: GraphqlBrand) => brand.slug === slug)?.id;
}

const brandSlugsCache: { [key: string]: string } = {};
export async function getBrandSlug(seriesId?: string): Promise<string> {
  if (!seriesId) {
    return "";
  }
  if (brandSlugsCache[seriesId]) {
    return brandSlugsCache[seriesId];
  }
  const data = await fetchFromFeedApi(`/get-brand/${seriesId}`);
  if (data?.brandSlug) {
    brandSlugsCache[seriesId] = data.brandSlug;
    return data.brandSlug;
  }
  return "";
}

export async function getBrand(_queryKey: string, { slug }: RouteParams): Promise<Brand | null> {
  const brandId = await getBrandIdBySlug(slug);

  if (!brandId || !slug) {
    return null;
  }

  const response = await getBrandRequest({
    brandId,
  });

  if (isEmpty(response.data?.brands)) {
    return null;
  }
  return new Brand(response.data.brands[0]);
}

export async function getBrandGuids(_queryKey: string, { slug, programType, sort }: RouteParams): Promise<string[]> {
  if (!slug) {
    return [];
  }
  const series = await getBrandSeries("", { brandSlug: slug });
  const seriesIds = series.map(({ id }) => id);

  if (isEmpty(seriesIds)) {
    return [];
  }
  const sortType = SORT_OPTIONS.find(({ key }) => key === sort.toUpperCase()) || SORT_OPTIONS[0];

  const programTypes = getProgramTypes({ programType });

  const response = await getBrandVideosRequest({
    limit: 30,
    skip: 0,
    programTypes: programTypes.value,
    sort: sortType.key,
    seriesIds,
    live: !!programTypes.isLive,
  });
  const videos: GraphqlProgram[] = Object.values(response.data).reduce((acc: GraphqlProgram[], series: any) => {
    return [...acc, ...series.items];
  }, []);

  return videos
    .sort(sortVideos(sortType.key))
    .map((video: GraphqlProgram) => video.guid || "")
    .slice(0, 100);
}

export async function getAllBrands(
  key: string,
  skip = 0
): Promise<{
  brands: Brand[];
  nextSkip: number;
  moreItemsExist: boolean;
}> {
  const limit = getLimit(6);
  const nextSkip = skip + limit;

  const response = await getAllBrandsRequest({
    limit,
    skip,
  });

  if (isEmpty(response.data?.brands)) {
    return {
      brands: [],
      nextSkip,
      moreItemsExist: false,
    };
  }

  return {
    brands: response.data.brands.map((brand: GraphqlBrand) => new Brand(brand)),
    nextSkip,
    moreItemsExist: response.data.brands.length >= limit,
  };
}

export async function getBrandSeries(
  key: string,
  {
    brandSlug,
  }: {
    brandSlug: string;
  }
): Promise<{ slug: string; id: string }[]> {
  if (!brandSlug) {
    return [];
  }
  const data = await fetchFromFeedApi(`/get-brand-series/${brandSlug}`);
  return data?.series || [];
}
