import { format, isPast } from "date-fns";
import { nl } from "date-fns/locale";
import { Program } from ".";
import { IMAGE_PREFIX, LIVE_VIDEO } from "../constants";
import { isEmpty } from "../utils";
import { GraphqlProgram } from "./graphql.model";
import { Series } from "./series.model";

export class Video extends Program {
  link!: string;
  embedlink?: string;
  transmissionDate?: string = "n/a";
  pubDate?: string;
  formatname?: string;
  videoImage = `${IMAGE_PREFIX}https://kijk.nl/static/images/default_editoriallandscape.png`;
  episodeNumber = "";
  brandSlug = "";
  series?: Series;
  unavailable: boolean;
  isLive: boolean;
  isCurrentlyLive: boolean = false;
  availableDate = "";

  constructor(data: GraphqlProgram) {
    super(data);
    if (data.series && data.series.slug) {
      this.formatname = data.series.slug;
    }

    if (data.id) {
      this.id = data.id;
    }

    if (data.guid) {
      this.link = `https://www.kijk.nl/video/${data.guid}`;
      this.embedlink = `https://embed.kijk.nl?width=500&height=283&video=${data.guid}`;
    }

    const [media] = data.media || [];
    this.isLive = media?.type === LIVE_VIDEO;

    if (typeof data.added === "number") {
      this.pubDate = format(new Date(data.added), "PPpp", { locale: nl });
    }

    if (typeof data.epgDate === "number") {
      this.transmissionDate = format(new Date(data.epgDate), "PP", {
        locale: nl,
      });
      this.isCurrentlyLive = this.isLive && isPast(data.epgDate);
    }
    const videoImage = data.imageMedia?.find((image) =>
      image.label?.includes("landscape")
    );
    if (videoImage) {
      this.videoImage = `${IMAGE_PREFIX}${videoImage.url}`;
    }

    if (data.seasonNumber) {
      this.episodeNumber += `S${data.seasonNumber < 9 ? "0" : ""}${
        data.seasonNumber
      }`;
    }

    if (data.tvSeasonEpisodeNumber) {
      this.episodeNumber += `E${data.tvSeasonEpisodeNumber < 9 ? "0" : ""}${
        data.tvSeasonEpisodeNumber
      }`;
    }

    if (data.series) {
      this.series = new Series({}, data.series);
    }

    if (this.title === this.series?.title && this.episodeNumber) {
      this.title = this.episodeNumber;
    }

    this.unavailable = !media?.availabilityState || isEmpty(data.sources);

    if (media?.availableDate) {
      this.availableDate = format(new Date(+media.availableDate), "PP, HH:mm", {
        locale: nl,
      });
    }
  }
}

export class VideoList {
  items: Video[] = [];

  constructor(data: { items: GraphqlProgram[] }) {
    if (!isEmpty(data.items)) {
      this.items = data.items.map((item) => new Video(item));
    }
  }
}
