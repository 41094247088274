import React from "react";

import {
  Dialog,
  DialogTitle,
  Modal,
  ButtonGroup,
  makeStyles,
  createStyles,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Embed from "./Embed";
import View from "./View";
import { TaqButton } from "../../TaqComponents";
import { copyToClipboard } from "../../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      padding: 5,
    },
    paper: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      maxHeight: "80%",
      maxWidth: "100%",
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      flexDirection: "column",
      boxShadow: theme.shadows[5],
      [theme.breakpoints.up("md")]: {
        width: 800,
        height: 560,
      },
    },
    title: {
      flexGrow: 1,
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      lineClamp: 1,
      boxOrient: "vertical",
    },
  })
);

interface EmbedLinksProps {
  seriesTitle?: string;
  embedlink?: string;
  title?: string;
  guid?: string;
  slug?: string;
  originalLandscapeImage?: string;
}
function EmbedLinks({
  slug,
  embedlink,
  guid = "",
  title = "",
  seriesTitle,
  originalLandscapeImage,
}: EmbedLinksProps) {
  const [open, setOpen] = React.useState<string>("");
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

  const classes = useStyles();

  const handleOpen = (target: string) => () => {
    setOpen(target);
  };

  const handleClose = () => {
    setOpen("");
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
    // AutoHide Dialog
    setTimeout(handleDialogClose, 500);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const copyToClipBoard = (text: string) => async () => {
    // Modal needs to be closed for copying
    handleClose();
    setTimeout(() => {
      copyToClipboard(text);
      handleDialogOpen();
    }, 100);
  };

  if (!embedlink || !title || !slug) return null;

  return (
    <>
      <Dialog
        data-testid="dialog"
        open={dialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle disableTypography>
          <Typography variant="body1">Gekopieerd!</Typography>
        </DialogTitle>
      </Dialog>
      <Modal open={!!open} onClose={handleClose}>
        <div className={classes.paper}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {seriesTitle} - {title}
            </Typography>
            <TaqButton
              color="inherit"
              onClick={handleClose}
              eventLabel={slug}
              eventCategory="videos"
              eventName="videos-Close"
            >
              Sluiten
            </TaqButton>
          </Toolbar>
          {open === "view" ? (
            <View
              slug={slug}
              embedlink={embedlink}
              onCopy={copyToClipBoard(guid)}
              title={title}
            />
          ) : (
            <Embed
              slug={slug}
              embedlink={embedlink}
              copyToClipBoard={copyToClipBoard}
            />
          )}
        </div>
      </Modal>
      <ButtonGroup
        fullWidth
        color="secondary"
        aria-label="outlined primary button group"
      >
        <TaqButton
          className={classes.button}
          onClick={handleOpen("view")}
          eventLabel={slug}
          eventCategory="videos"
          eventName="videos-Preview"
        >
          Preview
        </TaqButton>
        <TaqButton
          className={classes.button}
          onClick={handleOpen("embed")}
          eventLabel={slug}
          eventCategory="videos"
          eventName="videos-Embed"
        >
          Embed
        </TaqButton>
        <TaqButton
          className={classes.button}
          onClick={copyToClipBoard(guid)}
          eventLabel={slug}
          eventCategory="videos"
          eventName="videos-Video ID"
        >
          Video ID
        </TaqButton>
      </ButtonGroup>
    </>
  );
}

export default EmbedLinks;
