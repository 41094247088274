import React from "react";
import { useQuery } from "react-query";
import { ButtonGroup } from "@material-ui/core";
import { TaqButton } from "../../TaqComponents";
import { getAvailableLetters } from "../../utils";

const alphabet = ["all", ..."abcdefghijklmnopqrstuvwxyz".split("")];

interface AlphabetShortcutProps {
  onLetterChange: (letter: string) => void;
  activeLetter: string;
}

function AlphabetShortcut({
  onLetterChange,
  activeLetter,
}: AlphabetShortcutProps) {
  const {
    data: availableLetters,
  } = useQuery<string | null>("availableLetters", getAvailableLetters, {
    refetchOnWindowFocus: false,
  });

  const handleClick = (letter: string) => () => {
    onLetterChange(letter);
  };
  if (!availableLetters) {
    return <div style={{ height: 56 }} />;
  }
  return (
    <ButtonGroup
      color="secondary"
      aria-label="outlined primary button group"
      fullWidth
      style={{
        maxWidth: "100%",
        overflow: "scroll",
        paddingBottom: 10,
        marginBottom: 10,
      }}
    >
      {alphabet.map((letter) => (
        <TaqButton
          key={letter}
          variant={letter === activeLetter ? "contained" : "outlined"}
          onClick={handleClick(letter)}
          disabled={letter !== "all" && !availableLetters.includes(letter)}
          eventCategory="alphabet"
          eventName="alphabet-click"
        >
          {letter}
        </TaqButton>
      ))}
    </ButtonGroup>
  );
}

export default AlphabetShortcut;
