import axios from "axios";

const feedApiUrl =
  process.env.REACT_APP_FEED_API_ENDPOINT || window.location.host;
const protocol = feedApiUrl.includes('localhost') ? 'http' : 'https';

export async function fetchFromFeedApi(path = "/"): Promise<any> {
  try {
    const {
      data: { data, error },
    } = await axios({
      method: "get",
      url: `${protocol}://${feedApiUrl}/api${path}`,
      timeout: 4000,
    });

    if (error) {
      const custom = {
        error_message: error.message || "couldn't fetch from feed api",
        status_code: "COULD_NOT_FETCH_FROM_FEED_API",
      };
      window.newrelic.noticeError(error, custom);
      return null;
    }
    return data;
  } catch (error) {
    if (window.newrelic) {
      const custom = {
        error_message: (error as any).message || "missing message",
        status_code: "REJECTED_RESPONSE",
      };
      window.newrelic.noticeError(error, custom);
    }
    return null;
  }
}
