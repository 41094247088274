import { NavLink } from "react-router-dom";
import { ALL_SERIES_SLUG } from "./series.const";
export const links = [
  {
    to: "/",
    title: "Brands",
    inHeader: true,
    component: NavLink,
    exact: true,
  },
  {
    to: `/video/html/added/all-channels/all/${ALL_SERIES_SLUG}/`,
    title: "Alle video's",
    inHeader: true,
    component: NavLink,
  },
  {
    to: "/series-overview",
    title: "Programma's",
    inHeader: true,
    component: NavLink,
  },
  {
    to: "/content-planner",
    title: "Planner",
    inHeader: true,
    component: NavLink,
  },
  {
    to: "/video-feed",
    title: "Documentatie",
    inHeader: true,
    component: NavLink,
  },
  {
    href: "https://talpanetwork.com/",
    title: "Talpa Network",
    component: 'a',
    target: '_blank',
  },
  {
    href: "https://privacy.talpanetwork.com/nl/privacy/",
    title: "Privacy policy",
    component: 'a',
    target: '_blank',
  },
  {
    href: "https://privacy.talpanetwork.com/nl/cookies/",
    title: "Cookie policy",
    component: 'a',
    target: '_blank',
  },
  {
    href: "#consentmanager",
    title: "Cookie instellingen",
    component: 'a',
  },
  {
    href: "https://privacy.talpanetwork.com/nl/voorwaarden/",
    title: "Gebruiksvoorwaarden",
    component: 'a',
    target: '_blank',
  },
];
