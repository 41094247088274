export function isEmpty(value?: any): boolean {
  if (!value) {
    return true;
  }
  if (Array.isArray(value) || typeof value === "string") {
    return !value.length;
  }
  if (typeof value === "object") {
    return Object.keys(value).length === 0;
  }
  return false;
}
