import { GraphqlSeries, Series } from "../models";
import { ALL_SERIES_SLUG } from "../constants";
import { getSeriesByIdRequest } from "./graphql";
import { isEmpty } from "./isEmpty";
import { fetchFromFeedApi } from "./feedApi";
import getLimit from "./getLimit";

async function getSeriesGuid(slug: string) {
  const { series } = (await fetchFromFeedApi(`/get-series/${slug}`)) || {};
  return series || null;
}

export async function getSeriesBySlug(
  key: string,
  { slug }: { slug: string }
): Promise<Series | null> {
  if (slug === ALL_SERIES_SLUG) {
    return {
      title: "Alle programma's",
      slug: ALL_SERIES_SLUG,
      type: "SERIES",
      link: "",
      video: null,
      id: ALL_SERIES_SLUG,
      guid: ALL_SERIES_SLUG,
      genres: [],
    };
  }
  const { guid } = (await getSeriesGuid(slug)) || {};
  if (guid) {
    const response = await getSeriesByIdRequest({ guid });

    if (isEmpty(response.data.programs?.series)) {
      return null;
    }
    return new Series(response.data.programs.series[0]);
  }
  return null;
}

export async function getAllSeries(
  key: string,
  { letter = "all" },
  skip = 0
): Promise<{
  series: Series[];
  nextSkip: number;
  moreItemsExist: boolean;
  totalResults: number;
}> {
  const limit = getLimit(12);
  const nextSkip = skip + limit;
  let guids = null;
  let totalResults;
  if (letter !== "all") {
    const data = await fetchFromFeedApi(
      `/get-series-begin-with/${letter}/skip/${skip}/limit/${limit}`
    );
    totalResults = data.totalResults;
    if (isEmpty(data.series)) {
      return {
        totalResults: totalResults || 0,
        series: [],
        nextSkip,
        moreItemsExist: false,
      };
    }

    guids = data.series.map(({ guid }: Series) => guid);
  }

  const response = await getSeriesByIdRequest({
    guid: guids,
    limit,
    skip: guids ? 0 : skip,
    sort: "TITLE",
  });
  totalResults = totalResults || response.data.programs?.totalResults;
  if (isEmpty(response.data.programs?.series)) {
    return {
      totalResults,
      series: [],
      nextSkip,
      moreItemsExist: false,
    };
  }

  return {
    totalResults,
    series: response.data.programs.series.map(
      (item: GraphqlSeries) => new Series(item)
    ),
    nextSkip,
    moreItemsExist: true,
  };
}

export async function getAvailableLetters(): Promise<string | null> {
  const { availableLetters } =
    (await fetchFromFeedApi("/available-letters")) || {};
  return availableLetters || null;
}
