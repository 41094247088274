import { IMAGE_PREFIX } from "../constants";
import { isEmpty } from "../utils";
import { GraphqlBrand } from "./graphql.model";
import { Series } from "./series.model";

export class Brand {
  id!: string;
  title?: string;
  slug?: string;
  description?: string;
  series?: Series[];
  image? = `${IMAGE_PREFIX}https://kijk.nl/static/images/default_editoriallandscape.png`;

  constructor(data: GraphqlBrand) {
    if (data.id) {
      this.id = data.id;
    }
    if (data.title) {
      this.title = data.title;
    }
    if (data.slug) {
      this.slug = data.slug;
    }
    if (data.description) {
      this.description = data.description;
    }
    if (!isEmpty(data.headerMedia) && data.headerMedia[0].url) {
      this.image = `${IMAGE_PREFIX}${data.headerMedia[0].url}`;
    }
    if (!isEmpty(data.series)) {
      this.series = data.series.map((series) => new Series(series));
    }
  }
}
